<template>
    <div class="close-chat w-full lg:w-60onboarding0 lg:m-auto lg:rounded-lg lg:shadow-lg lg:h-auto">
        <div class="">
            <label>Thank you for chatting to us, you may close the chat window now</label>
            <div class="flex justify-center mt-4">
                <v-btn
                    class="font-sans mr-2 px-8 text-xl bg-grey infoLight2--text py-4 rounded-full"
                    color="warning"
                    depressed
                    style="font-size: 0.975rem"
                    @click="closeChatWindow"
                >close</v-btn>
                <v-btn
                    class="font-sans ml-2 px-8 text-xl bg-grey infoLight2--text py-4 rounded-full"
                    depressed
                    type="submit"
                    color="info"
                    style="font-size: 0.975rem"
                    @click="startNew"
                >start</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FinishedChat',
    methods: {
        closeChatWindow() {
            this.$emit('close-chat-window')
        },
        startNew() {
            this.$emit('start-new')
        }
    }
}
</script>
