<template>
    <div class="user-input-form-container">
        <form class="user-input-form">
            <input
                ref="userInput"
                tabIndex="0"
                placeholder="Write your message here!"
                class="user-input--text"
                v-model="userInput"
                @keypress.enter="handleKey"
            />
            <div class="user-input--buttons">
<!--                <v-btn icon small color="primary">-->
<!--                    <fa-icon-->
<!--                        :icon="['far', 'smile']"-->
<!--                        size="lg"-->
<!--                        class="text-grey-2 hover:text-primary"-->
<!--                    />-->
<!--                </v-btn>-->
                <v-btn icon small color="primary" :disabled="isUserInputEmpty" @click="sendMessage">
                    <fa-icon
                        icon="paper-plane"
                        size="lg"
                        class="text-grey-2 hover:text-primary"
                    />
                </v-btn>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'UserInput',
    data() {
        return {
            message: {},
            timeout: 600,
            sending: false,
            userInput: ''
        }
    },
    mounted() {
        this.$refs.userInput.focus()
        // this.$root.$on('message-sent', this.handleMessageSent)
    },
    methods: {
        sendMessage() {
            this.submitMessage()
            this.finishTyping()
        },
        handleKey(event) {
            event.preventDefault()
            if (!this.userInput) return
            if (event.keyCode === 13 && !event.shiftKey) {
                this.submitMessage()
                this.finishTyping()
            }
        },
        async submitMessage() {
            // this.sending = true
            const text = this.userInput
            this.$root.$emit('send-message', {text})
        },
        // async handleMessageSent() {
        //     this.finishTyping()
        //     this.sending = false
        // },
        finishTyping() {
            this.userInput = ''
        },
    },
    computed: {
        isUserInputEmpty() {
            return this.userInput == null || this.userInput === ''
        }
    }
    // computed: {
    //     sendButtonIcon() {
    //         if (this.sending) return 'spinner'
    //         return 'paper-plane'
    //     }
    // },
    // destroyed() {
    //     this.$root.$off('message-sent')
    // }
}
</script>
