<template>
    <div class="header flex-row justify-between">
        <h1 class="mb-3 px-3 ml-1 font-bold font-sans text-2xl">{{chatTitle}}</h1>
        <div class="flex items-center">
            <div class="flex items-center mr-6">
                <div
                    class="w-28 h-28 rounded-l p-1 text-xs border border-gray-400 justify-center flex items-center cursor-pointer bg-white hover:bg-grey-4"
                    :class="{'bg-grey-4 pointer-events-none': decreaseIsDisabled}"
                    @click="decreaseFontSize"
                >
                    <fa-icon
                        icon="minus"
                        size="md"
                        class="font-sans text-xs"
                    />
                </div>
                <div class="text-xs border border-gray-400 border-l-0 border-r-0 h-28 px-2 flex items-center">
                    <div class="font-sans font-bold">Text size</div>
                </div>
                <div
                    class="w-28 h-28 rounded-r p-1 text-xs border border-gray-400 justify-center flex items-center cursor-pointer bg-white hover:bg-grey-4"
                    :class="{'bg-grey-4 pointer-events-none': increaseIsDisabled}"
                    @click="increaseFontSize"
                >
                    <fa-icon
                        icon="plus"
                        size="md"
                        class="font-sans text-xs"
                    />
                </div>
            </div>
            <v-btn
                v-if="!showCloseChat"
                small
                class="font-sans text-sm bg-grey infoLight2--text py-3 rounded-full"
                color="info"
                depressed
                @click="closeConnection"
            >
                Finish
            </v-btn>
        </div>
    </div>
</template>
<script>
import {CHAT_TITLE, FONT_SIZES} from '@/config/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'Header',
    props: {
        showCloseChat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chatTitle: CHAT_TITLE,
            fontSizes: FONT_SIZES
        }
    },
    methods: {
        closeConnection() {
            this.$emit('close-connection')
        },
        increaseFontSize() {
            this.$root.$emit('increase-font-size')
        },
        decreaseFontSize() {
            this.$root.$emit('decrease-font-size')
        },
    },
    computed: {
        ...mapGetters(['fontSize']),
        increaseIsDisabled() {
            return this.fontSizes.indexOf(this.fontSize) === this.fontSizes.length - 1
        },
        decreaseIsDisabled() {
            return this.fontSizes.indexOf(this.fontSize) === 0
        }
    },
}
</script>
