<template>
    <div class="close-chat w-full lg:w-60onboarding0 lg:m-auto lg:rounded-lg lg:shadow-lg lg:h-auto">
        <div class="">
            <div class="grid">
                <label class="font-sans mb-2">Are you sure you want to finish the conversation?</label>
            </div>
            <div class="flex justify-center mt-4">
                <v-btn
                    class="font-sans mr-2 px-8 text-xl bg-grey infoLight2--text py-4 rounded-full"
                    depressed
                    type="submit"
                    color="info"
                    style="font-size: 0.975rem"
                    @click="cancel"
                >cancel</v-btn>
                <v-btn
                    class="font-sans ml-2 px-8 text-xl bg-grey infoLight2--text py-4 rounded-full"
                    color="warning"
                    depressed
                    style="font-size: 0.975rem"
                    @click="closeConnection"
                >Finish</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CloseChat',
    methods: {
        cancel() {
            this.$emit('cancel-close')
        },
        closeConnection() {
            this.$emit('close-chat')
        }
    }
}
</script>
