<template>
    <div class="message-list-and-user-input">
        <MessageList />
        <div
            v-if="isOperatorOnline && !hasSupportResponded"
            class="p-3 text-grey-2 bg-white-pure text-xs font-sans"
        >A customer service agent is now active and will respond shortly.</div>
        <UserInput />
    </div>
</template>

<script>
import UserInput from '@/components/chat/UserInput'
import MessageList from '@/components/chat/MessageList'
import {mapActions, mapGetters} from 'vuex'
import {AUTHORS} from '@/config/constants'

export default {
    name: 'MessageListAndUserInput',
    components: {UserInput, MessageList},
    computed: {
        ...mapGetters(['messages', 'isOperatorOnline']),
        /**
         * we check to see if there is more than 1 message from support, if there is they have responded
         * and we don't need to show the message anymore
         * @returns {boolean}
         */
        hasSupportResponded() {
            return this.messages.filter((m) => m.author === AUTHORS.SUPPORT).length > 1
        },
    },
    methods: {
        ...mapActions(['setIsAnOperatorOnline']),
    }
}
</script>
