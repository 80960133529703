<template>
    <div class="h-full flex flex-col mt-2">
        <Header :show-close-chat="showCloseChat" @close-connection="closeConnection" />
        <component :is="renderComponent" @cancel-close="cancelClose" @close-chat="closeChat" />
    </div>
</template>

<script>
import Header from '@/components/chat/Header.vue'
import CloseChat from '@/components/chat/CloseChat'
import MessageListAndUserInput from '@/components/chat/MessageListAndUserInput'
import {mapActions, mapGetters} from 'vuex'
import {AUTHOR_NAMES, AUTHORS, MESSAGE_STATUSES} from '@/config/constants'
import {MessageModel} from '@/models/message'

export default {
    name: 'Chat',
    components: {Header, MessageListAndUserInput, CloseChat},
    data() {
        return {
            showCloseChat: false,
            message: null
        }
    },
    mounted() {
        // on mount of this component, we connect to the websocket
        this.$connect()

        this.$root.$on('send-message', this.handleSendMessage)
        this.$options.sockets.onmessage = this.handleSocketMessage
        this.$options.sockets.onopen = this.handleOnOpen
    },
    methods: {
        ...mapActions([
            'setToken',
            'setFinished',
            'addMessage',
            'removeWebChat',
            'popMessage',
            'pushMessage',
            'setWelcomeText',
            'resetMessages',
            'setIsAnOperatorOnline'
        ]),
        closeConnection() {
            this.showCloseChat = true
        },
        cancelClose() {
            this.showCloseChat = false
        },
        async handleOnOpen(data) {
            console.log('data', data)
            this.$socket.sendObj({
                action: 'assignToken',
                token: this.authToken
            })
        },
        async handleSendMessage(data) {
            const message = new MessageModel(
                this.$uuid.v4(),
                AUTHORS.ME,
                'John Smith',
                data,
                new Date().getTime()
            )
            this.pushMessage(message)
            await this.addMessage(message)
            this.$socket.sendObj({
                action: 'message',
                token: this.authToken,
                message: message.data.text
            })
            // this.message = message
        },
        async handleSocketMessage(event) {
            const data = JSON.parse(event.data)

            if (data.StartMessage) {
                await this.setWelcomeText(data.StartMessage)
                await this.setIsAnOperatorOnline(data.isAnOperatorOnline)
                await this.resetMessages()
            } else if (data.STATUS === MESSAGE_STATUSES.SUBMITTED) {
                this.popMessage()
            } else if (data.message) {
                // if we have data.message, then we got a response
                const message = new MessageModel(
                    null,
                    AUTHORS.SUPPORT,
                    AUTHOR_NAMES.SUPPORT,
                    {text: data.message},
                    new Date().getTime()
                )
                await this.addMessage(message)
            } else if (data.ERROR === MESSAGE_STATUSES.INVALID_SESSION) {
                // here we have an error
                console.error(
                    'there was an error sending the message, maybe retry or prompt the user to try again'
                )
                this.$store.commit('ALERT_IS_VISIBLE', true)
                this.$store.commit('ALERT_DATA', {
                    text: 'You were disconnected, please try reconnecting...',
                    color: 'red'
                })
                // error or something else happened
                setTimeout(() => {
                    this.$disconnect()
                    this.removeWebChat()
                }, this.timeout)
            }
        },
        closeChat() {
            // check if we have any listeners first
            if (this.$options.sockets.onmessage) delete this.$options.sockets.onmessage
            this.setToken('')
            this.setFinished(true)
            this.setIsAnOperatorOnline(false)

            this.$disconnect()
            this.$router.push({name: 'finished-chat'})
        }
    },
    computed: {
        ...mapGetters(['authToken', 'welcomeText']),
        renderComponent() {
            return this.showCloseChat ? 'CloseChat' : 'MessageListAndUserInput'
        }
    },
    destroyed() {
        this.$root.$off('send-message')
        this.$disconnect()
    }
}
</script>
