<template>
    <div class="message" :class="`message-${message.author}`">
        <div v-if="avatarIcon" class="avatar" :title="message.name">
            <fa-icon :icon="avatarIcon" size="lg"/>
        </div>
        <div class="data flex flex-row justify-between">
            <div class="text font-sans" v-html="text" :style="`font-size: ${fontSize}em`"></div>
            <div v-if="isQueued" class="pt-2 pl-2">
                <fa-icon icon="spinner" size="lg" class="text-grey-2 hover:text-primary" :spin="isQueued"/>
            </div>
        </div>
    </div>
</template>
<script>
import {AUTHORS} from '@/config/constants'
import {MessageModel} from '@/models/message'

export default {
    name: 'Message',
    props: {
        message: {
            type: Object,
            default: () => new MessageModel()
        },
        isQueued: {
            type: Boolean,
            default: false
        },
        fontSize: {
            type: Number,
            default: () => 1
        }
    },
    data() {
        return {
            attributes: {
                target: '_blank'
            }
        }
    },
    computed: {
        isSupport() {
            return this.message.author === AUTHORS.SUPPORT
        },
        avatarIcon() {
            return this.isSupport ? 'chalkboard-teacher' : null
        },
        text() {
            const text = this.message.data?.text || ''
            return this.$sanitize(
                this.$anchorme({input: text, options: { attributes: this.attributes }})
            )
        }
    }
}
</script>
