<template>
    <div ref="messageListContainer" class="message-list-container">
        <ul class="message-list">
            <li v-for="(message, i) in messages" :key="`message-${i}`">
                <Message :message="message" :is-queued="isInQueue(message.id)" :fontSize="fontSize" />
            </li>
        </ul>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Message from './Message.vue'
import {MessageListMixins} from '@/components/shared/mixins/messageMixins'
import {FONT_SIZES} from '@/config/constants'

export default {
    components: {Message},
    name: 'MessageList',
    mixins: [MessageListMixins],
    data() {
        return {
            fontSizes: FONT_SIZES, // Sizes in em
            fontSizeSelected: 0
        }
    },
    mounted() {
        this.setFontSize(this.fontSizes[this.fontSizeSelected])
        this.$root.$on('message-list-scroll-to-bottom', this.scrollToBottom)
        this.$root.$on('increase-font-size', () => {
            this.fontSizeSelected++
            if (this.fontSizeSelected >= this.fontSizes.length) this.fontSizeSelected = this.fontSizes.length - 1
            this.setFontSize(this.fontSizes[this.fontSizeSelected])
        })
        this.$root.$on('decrease-font-size', () => {
            this.fontSizeSelected--
            if (this.fontSizeSelected < 0) this.fontSizeSelected = 0
            this.setFontSize(this.fontSizes[this.fontSizeSelected])
        })
        this.scrollToBottom()
    },
    computed: {
        ...mapGetters(['messages', 'messageQueue', 'isOperatorOnline']),
        fontSize() { 
            return this.fontSizes[this.fontSizeSelected]
        }
    },
    methods: {
        ...mapActions(['setFontSize', 'setIsAnOperatorOnline']),
        scrollToBottom() {
            this.$refs.messageListContainer.scrollTop = this.$refs.messageListContainer.scrollHeight
        },
        isInQueue(id) {
            return this.messageQueue.find(m => m.id === id) != null
        }
    },
    watch: {
        messages() {
            if (this.messages && this.messages.length) this.$nextTick(this.scrollToBottom)
        }
    },
    destroyed() {
        this.$root.$off('message-list-scroll-to-bottom')
    }
}
</script>
