const MessageListMixins = {
    methods: {
        scrollToElement(options, idx) {
            if (idx == null) idx = this.messages.length - 1
            this.$nextTick(() => {
                const el = this.$el.getElementsByClassName(`message-${idx}`)[0]

                if (el) {
                    el.scrollIntoView(options)
                }
            })
        },
    }
}

export {
    MessageListMixins
}
