<template>
    <div class="h-full flex flex-col">
        <Header
            :show-close-chat="true"
        />
        <FinishedChat
            @close-chat-window="close"
            @start-new="startNew"
        />
    </div>
</template>

<script>
import Header from '@/components/chat/Header.vue'
import {mapActions, mapGetters} from 'vuex'
import FinishedChat from '@/components/chat/FinishedChat'
import CustomerAppHelper from '@/components/shared/mixins/customerAppHelper'
import {CUSTOMER_APP_SIGNALS} from '@/config/constants'

export default {
    name: 'ChatFinished',
    components: {Header, FinishedChat},
    mixins: [CustomerAppHelper],
    data() {
        return {
            showCloseChat: false,
        }
    },
    computed: {
        ...mapGetters(['customerAppOrigin'])
    },
    methods: {
        ...mapActions(['setToken', 'removeWebChat']),
        async close() {
            await this.startNew()
            this.sendSignalToCustomerApp({signal: CUSTOMER_APP_SIGNALS.CLOSE})
        },
        async startNew() {
            await this.removeWebChat()
            await this.$router.push({name: 'onboarding'})
        }
    },
}
</script>
